export default {
	langname: 'Eng',
  'meta': {
    'description': 'Online training on industrial safety, fire safety, safety and labor protection with the issuance of certificates'
  },
	company: {
		'name': 'Companiya AZiYA 2012 LLC',
	},
	ui: {
		'logo': 'Logo',
		'back': 'Back',
		'login': 'Login',
		'open-menu': 'Open menu',
		'close-menu': 'Close menu',
	},
	footer: {
		'rights': '"Companiya AZiYA 2012" LLC. The information provided on the website regarding the cost of services is informational in nature and under no circumstances is a public offer.',
	},
	menu: {
		'home': 'Home',
		'about-us': 'About us',
    'prices': 'Prices',
    'contacts': 'Contacts',
    'requisites': 'Requisites',
	},
}
