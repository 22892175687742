export default {
	langname: 'Рус',
  'meta': {
    'description': 'Онлайн обучение по промышленной безопасности, пожарной безопасности, безопасности и охране труда с выдачей удостоверений, сертификатов'
  },
	company: {
		'name': 'ТОО Компания АЗиЯ 2012',
	},
	ui: {
		'logo': 'Логотип',
		'back': 'Назад',
		'login': 'Войти',
		'open-menu': 'Открыть меню',
		'close-menu': 'Закрыть меню',
	},
	footer: {
		'rights': 'ТОО "Компания АЗиЯ 2012". Информация представленная на сайте, касающаяся стоимости услуг, носит информационный характер и ни при каких условиях не является публичной офертой. ',
	},
	menu: {
		'home': 'Главная',
		'about-us': 'О нас',
    'prices': 'Прайс лист',
    'contacts': 'Контакты',
    'requisites': 'Реквизиты',
	},
}
